<template>
    <div>
        <div class="header">
            <div class="ml-5">
                <img
                    ref="agencyLogo"
                    alt="Agency logo"
                    :style="{
                        maxWidth: '200px',
                        maxHeight: '35px'
                    }"
                    :src="$brand.asset_urls.navbar_logo">
            </div>
            <div class="header-actions">
                <v-menu
                    v-model="supportMenuOpen"
                    transition="slide-y-transition"
                    bottom
                    left
                    nudge-right="16"
                    nudge-bottom="3"
                    offset-y
                    allow-overflow
                    :nudge-width="60"
                    fixed
                    :close-on-content-click="false">
                    <template #activator="{ on }">
                        <button
                            class="exit-button-wrapper"
                            v-on.prevent="on">
                            <span class="help-label mr-2">Need Help?</span>
                            <icon
                                name="life-buoy"
                                color="white"
                                width="20"
                                height="20" />
                        </button>
                    </template>
                    <div class="triangle triangle-header">
                        -
                    </div>
                    <support-options-dropdown
                        @slideout-opened="supportMenuOpen = false" />
                </v-menu>
                <span class="header-actions-separator" />
                <action-button
                    icon="indicator-error"
                    position="left"
                    light
                    normal-text
                    :loading-deployment="loadingDeployment"
                    @click="saveAndExit()">
                    Save &amp; Exit
                </action-button>
            </div>
        </div>
        <div class="sub-header">
            <div class="sub-header__inner">
                <div class="sub-header__dep">
                    Deployment for:&nbsp;
                </div>
                <div class="sub-header__dealer">
                    {{ deploymentDealerName }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';
// import DealerPickerGlobal from '@/components/globals/DealerPickerGlobal';
import Icon from '@/components/globals/Icon';
import SupportOptionsDropdown from '@/components/globals/SupportOptionsDropdown';
import { mapState } from 'vuex';


export default {
    components: {
        // DealerPickerGlobal,
        ActionButton,
        SupportOptionsDropdown,
        Icon
    },
    data() {
        return {
            supportMenuOpen: false,
            loadingDeployment: false,
        };
    },
    computed: {
        ...mapState({
            brand: (state) => state.brand,
            user: (state) => state.user.user,
            currentDealer: (state) => state.dealer.currentDealer,
            currentDealerId: (state) => state.dealer.currentDealerId,
            deploymentId: (state) => state.adDeployment.ad_deployment_id,
        }),
        deploymentDealerName() {
            return this.$store.state.adDeployment?.dealer_name || this.currentDealer.name;
        },
        duAdOpsTicketLink() {
            return `https://form.jotform.com/buyerbridge/dealers-united-ticket-submission?bbdealerid=${this.currentDealerId}&dealerName=${encodeURIComponent(this.currentDealer?.name)}&whoIs=${encodeURIComponent(this.user?.name)}`;
        }
    },
    methods: {
        async saveAndExit() {
            this.loading = true;
            await this.$store.dispatch('adDeployment/save');
            this.$router.push({
                name: 'campaign-management',
                params: {
                    dealer_id: this.currentDealerId
                },
                query: {
                    ad_deployment_id: this.deploymentId
                }
            });
            await this.$store.dispatch('adDeployment/clear');
            this.loading = false;
        }
    },
};
</script>

<style lang="scss" scoped>
//header
.header {
    background-color: $gray;
    color: $white;
    height: 60px;
    display: flex;
    &.fixed {
        position: fixed;
        left: 0;
        right: 0;
        z-index: 55;
    }
    & > div {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .header-actions {
        margin-left: auto;
        padding: 0 20px;
    }
    .header-actions-separator {
        margin: 0 20px;
        background-color: $white;
        height: 30px;
        width: 1px;
    }
}
.triangle-header:before {
    border-color: transparent transparent white transparent;
}
.v-menu__content, .v-card {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2) !important;
    border-radius: 0 !important;
}
.sub-header{
    background: #F4F5F6;
    padding: 15px 0px;
    &__inner{
        margin: 0 auto;
        max-width: 800px;
        display: flex;
        align-items: center;
    }
    &__dep{
        font-size: 16px;
        color: #808080;
    }
    &__dealer{
        font-size: 16px;
        color: #03A2EA;
        margin-left: 5px;
    }
}
</style>

