<template>
    <div class="main-wrapper">
        <div
            v-if="loading"
            class="fill-height layout column justify-center">
            <styled-loader
                label="Loading deployment..."
                size="40" />
        </div>
        <template v-else>
            <deployment-header />
            <div class="deployment-wrapper">
                <component
                    :is="component"
                    v-for="({ key, component, valid, name }, index) in displayedSteps"
                    :key="key"
                    ref="steps"
                    :name="name"
                    :active="index === currentStep"
                    :valid="valid"
                    :index="index" />
            </div>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import StyledLoader from '@/components/globals/StyledLoader';
import DeploymentHeader from './components/DeploymentHeader';
import { sleep } from '@/helpers';

export default {
    name: 'AdDeployment',
    title: 'Ad Deployment',
    components: {
        StyledLoader,
        DeploymentHeader,
    },
    computed: {
        ...mapState({
            currentStep: (state) => state.adDeployment.current_step,
            latestStep: (state) => state.adDeployment.latest_step,
            adDeployment: (state) => state.adDeployment,
            platforms: (state) => state.adDeployment.platforms,
            channels: state => state.adDeployment.platforms,
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        ...mapGetters({
            currentSteps: 'adDeployment/currentSteps'
        }),
        displayedSteps() {
            return this.currentSteps.slice(0, this.latestStep + 1);
        },
        loading: {
            get() {
                return this.$store.state.adDeployment.loading || this.$store.state.adDeployment.loading === undefined;
            },
            set(value) {
                this.$store.commit('adDeployment/setLoading', value);
            }
        }
    },
    watch: {
        currentStep(current, previous) {
            if (current > previous) {
                this.scrollToActive();
            }
        }
    },
    mounted() {
        this.scrollTimeout = null;
    },
    created() {
        this.init();
    },
    methods: {
        ...mapActions({
            initAdDeployment: 'adDeployment/init',
            loadAdDeployment: 'adDeployment/load'
        }),
        scrollToActive() {
            if (!this.$refs.steps?.length) {
                return;
            }
            clearTimeout(this.scrollTimeout);
            this.scrollTimeout = setTimeout(() => {
                const {
                    $el
                } = this.$refs.steps[this.currentStep];
                this.$scrollTo($el, 500, {
                    easing: 'ease-in',
                    offset: -150
                });
            }, 600);
        },
        async init() {
            this.loading = true;
            await this.loadAdDeployment(
                this.$route.params.ad_deployment_id
            );

            // Check if the dealer id in the url matches
            // the dealer id in the ad deployment
            if (this.adDeployment.dealer_id !== this.$route.params.dealer_id) {
                // It doesn't match, redirect to the correct url
                // with the correct dealer id
                this.$router.replace({
                    name: 'ad-deployment',
                    params: {
                        dealer_id: this.adDeployment.dealer_id,
                        ad_deployment_id: this.$route.params.ad_deployment_id
                    }
                });
            }
            this.loading = false;

            if (this.latestStep > 0) {
                // Wait for re-render and scroll to the latest
                // if this is an existing deployment
                await this.$nextTick();

                // I can't find a way around this!
                await sleep(300);

                this.scrollToActive();
            }

        }
    }
};
</script>

<style scoped>
.main-wrapper{
    position: relative;
    top:-24px;
    left:-24px;
    right:-24px;
    margin-bottom: -48px;
    min-height: calc(100vh - 48px);
    width: calc(100% + 48px);
    background: #F7F8FC;
    height: 100%;
}
</style>

<style lang="scss">
//common styles for each step
.deployment-wrapper{
    padding-top: 30px;
    padding-bottom: 300px;
}
.main-wrapper{
    .top-bar-variant.styled-select .v-input__slot{
        border: none !important;
    }
    .top-bar-variant.styled-select .v-input__slot .v-label{
        display: none;
    }
    .top-bar-variant.styled-select .v-input__slot input,
    .top-bar-variant.styled-select .v-input__slot .v-icon{
        color: #03A2EA !important;
    }
}
.step-sad-wrapper{
    max-width: 800px;
    margin: 0 auto;
    padding-left: 60px;
}
.button-sad-holder{
    padding-top: 15px;
}
.sad-edit{
    margin-bottom: 10px;
    margin-left: 15px;
    text-decoration: underline;
    cursor: pointer;
    color: #03A2EA;
}
</style>

